function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function Contact() {
	
    return (
      <div className="contact-main">
                <div class="page-heading about-page-heading" id="top">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="inner-content">
                                    <h2>Your NFT certificate is ready:</h2>
									<div class="main-border-button-2">
                                        <a
											id="downloadButton"
											href={"data:text/plain;charset=utf-8," + makeid(256)}
											download='NFT.cert'>
												Download
										</a>
                                    </div>
									<div style={{marginTop: 20}}>
                                    <span style={{marginTop: 10}}>Thank you for purchase!</span>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}
export default Contact;